<template>
  <div class="report-issue-form-container">
    <h2 class="left">REPORT AN ISSUE</h2>
    <el-form
      ref="form"
      :model="ruleForm"
      status-icon
      :rules="rules"
      label-width="120px"
      class="issue-form"
      size="medium"
    >
      <el-form-item class="right" prop="name" label-width="0">
        <el-input
          placeholder="name"
          v-model="ruleForm.name"
          type="text"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item class="left" prop="email" label-width="0">
        <el-input
          placeholder="email"
          v-model="ruleForm.email"
          type="text"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item class="text" prop="message" label-width="0">
        <el-input
          placeholder="Please Describe your issue"
          type="textarea"
          v-model="ruleForm.message"
          :input-style="{ height: '150px' }"
        ></el-input>
      </el-form-item>
      <el-form-item class="right" label-width="0">
        <el-button type="info" @click="submitForm()" round>Submit</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { ref } from 'vue'
export default {
  name: 'ReportIssueForm',
  emits: ['FORM-SUBMITTED'],
  setup (props, context) {
    const form = ref(null)

    const ruleForm = ref({
      name: '',
      email: '',
      message: ''
    })

    const isEmail = (email) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    }

    const validateName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('Please input your name'))
      }
      callback()
    }

    const validateEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input your email'))
      }
      if (isEmail(value)) {
        callback()
      } else {
        callback(new Error('Please enter a valid email address'))
      }
    }

    const validateMessage = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please describe your issue'))
      }
      callback()
    }

    const rules = ref({
      name: [{ validator: validateName, trigger: 'blur' }],
      email: [{ validator: validateEmail, trigger: 'blur' }],
      message: [{ validator: validateMessage, trigger: 'blur' }]
    })

    const submitForm = async (forName) => {
      form.value.validate((valid, formValues) => {
        if (valid) {
          context.emit('FORM-SUBMITTED', formValues)
          return true
        } else {
          console.log('Error:: Report Issues Submit')
          return false
        }
      })
    }

    return { ruleForm, rules, submitForm, form }
  }
}
</script>

<style scoped lang="scss">
.report-issue-form-container {
  max-width: 500px;
  margin: 0 auto;
  h2 {
    font-weight: bold;
  }
}
</style>
